import React, { useEffect } from "react"

import Layout from "components/Layout"
import SEO from "components/SEO"
import { LoginFormWrapper } from "../components/UserSystem/LoginOnSubmit"

import handleAlerts from '../functions/handleAlerts'

const LoginPage = ({ location }) => {
  useEffect(() => {handleAlerts(location)}, []);

  return (
    <Layout>
      <SEO title="User Login" />

      <LoginFormWrapper />
      
    </Layout>
  )
}

export default LoginPage