import React, { useContext } from "react";
import { siteUrl } from '../../siteConfig'
import { messageService } from '../../services/message.service'
import navigateWithAlert from '../../functions/navigateWithAlert'
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import LoginForm from './LoginForm'

// attempt login to drupal system
const LoginOnSubmit = (identifier, password, login) => {

  const successFunc = (response) => {
    // console.log('[LoginForm -> successFunc] 200 response: ', response)

    messageService.sendMessageAutoClose('You are now logged in', 'success');
    const data = response.data
    // todo: notify user + navigate to myBucktlist page
    navigateWithAlert('/MyBucktlist', 'Successfully logged in')
  }

  const failureFunc = (e, msg) => {
    // console.log('[LoginForm -> failureFunc] ERROR response: ', e.response)
    
    messageService.sendMessageAutoClose(msg, 'error');
  }

  const promise = login ({ identifier, password, successFunc, failureFunc })
}

export default LoginOnSubmit

// needs wrapping due to the useContext call.
export const LoginFormWrapper = () => {
  const { login } = useContext(CurrentUserContext)

  const wrappedOnSubmit = ( identifier, password ) => {
    LoginOnSubmit(identifier, password, login)
  }

  return (
    <>
      <LoginForm onSubmit={wrappedOnSubmit} />
    </>
  )

}