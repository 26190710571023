import React, { useState, useContext }  from "react";

import { FormErrors, FormCheckbox } from "./styles"

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Link } from "gatsby"
import UserSystemNavigation from "../Navigation";
// import { navigate } from "@reach/router";

// display a gatsby fluid image
const LoginForm = ({ onSubmit, rememberMeOn = false }) => {
    console.log('[LoginForm] render "remember me": ', rememberMeOn)
    // dummy values (if in dev mode)
    const devMode = process.env.GATSBY_DEVMODE

    const identifierInitial = (devMode) ?    'New To Melbourne' : '';
    const passwordInitial = (devMode) ? '' : '';

    const [identifier, setIdentifier]               = useState(identifierInitial);
    const [password, setPassword]         = useState(passwordInitial);
    const [_remember_me, set_remember_me] = useState(false);

    const [identifierWarning, setIdentifierWarning]       = useState("");
    const [passwordWarning, setPasswordWarning] = useState("");
    
    const [errorMsg, setErrorMsg] = useState("");

    function validateForm() {
        return identifier.length > 0 && password.length > 0;
    }

    async function HandleSubmit(event) {
      console.log('[HandleSubmit] event: ', event)
      event.preventDefault();

      setIdentifierWarning((!identifier.length > 0 ) ? "Error: username cannot be empty" : "");
      setPasswordWarning((!password.length > 0 ) ? "Error: password cannot be empty" : "");

      onSubmit(identifier, password)
    }

  return (
    <>
        <div className="Login">
        {errorMsg && (
          <FormErrors>
            {errorMsg}
          </FormErrors>
          )
        }
        
        {/* <Container>
          <Row>
            <Col xs={3} md={3} lg={3}>

            </Col>
          </Row>
        </Container> */}
            
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'600px'}}>
            <h2>Login:</h2>
            <UserSystemNavigation currentRoute="/login" />
            To login, complete the form below.
            
            {/* <br /><br />To create a new account, click here: signup */}
            <br /><br />
            <Form onSubmit={HandleSubmit}>
                <Form.Group size="lg" controlId="identifier">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                    autoFocus
                    type="text"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    />
                    <div>
                    {identifierWarning}
                    </div>
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    <div>
                    {passwordWarning}
                    </div>
                </Form.Group>
                {rememberMeOn &&
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Remember me?</Form.Label>
                        <FormCheckbox
                        // className="test"
                        type="checkbox"
                        checked={_remember_me}
                        onChange={(e) => {
                            set_remember_me(e.target.checked)
                            // console.log('#sdca32dwe: ', e.target.checked)
                        }}
                        />
                    </Form.Group>
                }
              <Button block size="lg" type="submit" disabled={!validateForm()}>
                Login
              </Button>
            </Form>
          </div>
        
        </div>
      </div>
    </>
  );
    
}

export default LoginForm